import React, { useEffect, useState, useRef } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';
import { useAuth } from '../context/auth';
import { useStateCtx } from '../context/appContext';
import {
  NumberCell,
  handleWheelIncomeDeleteConfirm,
  handleWheelIncomeSave,
} from '../utils/tableHandlers';
import { ConfirmationModalContent } from '../utils/tableHelpers';
import { calculateLeftOffset, calculateWidth } from '../utils/mathHelpers';
import TableFooter from '../components/Table/TableFooter';
import TableBody from '../components/Table/TableBody';
import TableHeader from '../components/Table/TableHeader';
import TableActionControls from '../components/TableToolBar/TableActionControls';
import SearchAndColumnToggle from '../components/TableToolBar/SearchAndColumnToggle';
import { ImEyePlus, ImPlus } from 'react-icons/im';
import { WheelIncomePutsColumns } from '../constants/WheelIncomePutsColumns';
import { createNewWheelIncomeCallTrade, createNewWheelIncomePutTrade, createNewWheelIncomeTrade } from '../constants/newRowsData';
import { fetchWheelTradeById, fetchWheelTrades } from '../services/WheelIncomeServices';
import FilterControls from '../components/TableToolBar/FilterControls';
import { WatchListColumns } from '../constants/WatchListColumns';
import { WheelIncomeCallsColumns } from '../constants/WheelIncomeCallsColumns';
import { MdAdd } from 'react-icons/md';
import CashRequirements from '../components/CashRequirements';
import { BsQuestionOctagon } from 'react-icons/bs'
import { guidedWheelIncome } from '../constants/guidedTutorialData';
import { guidedTutorialHandler } from '../utils/GuidedTutorialFun';

function WheelIncome() {
  // CONTEXT
  const { token, logout, visitedPages, markPageAsVisited, visitedPagesLoading } = useAuth();
  const { screenSize, activeMenu, currentColor } = useStateCtx();
  // STATES
  const [data, setData] = useState([]); // use this as your data source
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [contextMenuState, setContextMenuState] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState([]);
  const [sort, setSort] = useState(true);
  const [newRow, setNewRow] = useState(false);
  const [sellingType, setSellingType] = useState('puts')
  const [footerSums, setFooterSums] = useState({
    totalOptionsValue: 0,
    totalIfAssigned: 0,
    totalFinalPL: 0,
  });

  const handleDelete = (wheelIncomeItem) => {
    setModalState({ isOpen: true, wheelIncomeItem });
  };

  const editableColumns = [
    // Define which columns are editable
    'ticker',
    // Add more columns as needed
  ];
  const useSkipper = () => {
    const shouldSkipRef = React.useRef(true);
    const shouldSkip = shouldSkipRef.current;

    // Wrap a function with this to skip a pagination reset temporarily
    const skip = React.useCallback(() => {
      shouldSkipRef.current = false;
    }, []);

    React.useEffect(() => {
      shouldSkipRef.current = true;
    });

    return [shouldSkip, skip];
  };

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();
  const updateData = async ({ rowIndex, rowId, columnId, value }) => {
    skipAutoResetPageIndex();

    try {
      const updateRowWithFetchedData = async () => {
        const updatedTrade = await fetchWheelTradeById(rowId, token, sellingType);
        const fieldsToUpdate = [
          'WheelTicker',
          'WheelIncomeName',
          'WheelSector',
          'WheelIndustry',
          'WheelCurrentPrice',
        ];

        setData(old => old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...row,
              ...fieldsToUpdate.reduce((acc, field) => {
                if (updatedTrade[field] !== undefined) {
                  acc[field] = updatedTrade[field];
                }
                return acc;
              }, {}),
            };
          }
          return row;
        }));
      };

      if (columnId === 'WheelTicker' && rowId) {
        updateRowWithFetchedData();
      } else {
        setData(oldData => oldData.map((row, index) => {
          if (index === rowIndex) {
            return { ...row, [columnId]: value };
          }
          return row;
        }));
      }
    } catch (error) {
      if (error.response?.data?.error === 'Invalid token.') logout();
    }
  };
  const columns = sellingType === 'puts' ? WheelIncomePutsColumns({ handleDelete, updateData, columnVisibility, footerSums, sellingType }) : WheelIncomeCallsColumns({ handleDelete, updateData, columnVisibility, footerSums, sellingType });
  const defaultColumn = {
    cell: (info) => {
      return (
        <NumberCell
          key={info.row.id}
          updateData={updateData}
          row={info.row}
          getValue={info.row.getValue}
          columnId={info.column.id}
          page='WheelIncome'
          sellingType={sellingType}
        />
      );
    },
  };

  const table = useReactTable({
    data,
    defaultColumn,
    columns,
    state: {
      columnVisibility,
      columnOrder,
      sorting,
      globalFilter,
    },
    enableSorting: sort,
    onGlobalFilterChange: setGlobalFilter,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    columnResizeMode: 'onChange',
    enableColumnResizing: true,
    onColumnOrderChange: setColumnOrder,
    autoResetPageIndex,
    meta: {
      updateData,
      addRow: () => {
        const newRow = sellingType === 'puts' ? createNewWheelIncomePutTrade() : createNewWheelIncomeCallTrade()
        setData((oldData) => [newRow, ...oldData]);
        setNewRow(true);
      },
    },
  });

  const [modalState, setModalState] = useState({ isOpen: false, wheelIncomeItem: null });

  const originalDataRef = useRef([]);

  const fetchData = async () => {
    try {
      const wheelIncomes = await fetchWheelTrades(token, sellingType);
      originalDataRef.current = wheelIncomes || [];
      setData(originalDataRef.current);
    } catch (error) {
      console.error('Error fetching trades:', error);
      if (error.response?.data?.error === 'Invalid token.') logout();
    }
  };

  useEffect(() => {
    fetchData();
  }, [token, sellingType]);

  const pageName = 'WheelIncome'; // Define a unique name for this page
  useEffect(() => {
    if (!visitedPagesLoading && token) {
      // Check if the page has not been visited and trigger the tutorial
      if (!visitedPages[pageName] || visitedPages[pageName] === false) {
        // guidedTutorialHandler(guidedWheelIncome);
        // markPageAsVisited(pageName); // Mark the page as visited
      }
    }
  }, [visitedPages, markPageAsVisited, pageName, visitedPagesLoading]);

  // Render the table
  return (
    <div
      style={{ width: calculateWidth(activeMenu, screenSize) }}
      className='flex flex-col bg-light-background p-4 h-full mx-auto '
    >
      <div className='flex w-full items-center justify-between mb-6 mt-2 gap-4'>
        <h1 className='text-3xl font-semibold text-light-text'>Wheel trading calculator</h1>
        <button className='animate-scale-once mr-4 ' onClick={() => guidedTutorialHandler(guidedWheelIncome)}><BsQuestionOctagon className='w-9 h-9 fill-orange-500' /></button>
      </div>
      <ConfirmationModalContent
        isOpen={modalState.isOpen}
        title='Delete Wheel Income Item'
        message='Are you sure you want to remove this item from your wheel income? This action cannot be undone.'
        onConfirm={() =>
          handleWheelIncomeDeleteConfirm({
            modalState,
            setModalState,
            token,
            setData,
            logout,
            sellingType,
          })
        }
        onCancel={() => {
          setModalState({ isOpen: false, trade: null });
        }}
      />

      <div id='toolbar' className="flex flex-col lg:flex-row justify-between items-center text-md mb-4 bg-gradient-to-r from-gray-200 to-gray-300 px-4 py-2 rounded-lg shadow-md transition-all duration-300 ease-in-out" >
        <SearchAndColumnToggle
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          contextMenuState={contextMenuState}
          setContextMenuState={setContextMenuState}
          table={table}
        />
        <div id={'sellingPutsAndCallsTabs'} className="flex items-center justify-center text-md ">
          <button
            className={`px-4 py-1 transition-colors duration-300 ease-in-out ${sellingType === 'puts' ? 'bg-green-600 text-white shadow-md' : 'text-green-600 bg-white hover:bg-green-100'} font-bold rounded-md mx-1`}
            onClick={() => {
              setData([])
              setSellingType('puts')
            }}
          >
            Selling Puts
          </button>
          <button
            className={`px-4 py-1 transition-colors duration-300 ease-in-out ${sellingType === 'calls' ? 'bg-red-600 text-white shadow-md' : 'text-red-600 bg-white hover:bg-red-100'} font-bold rounded-md mx-1`}
            onClick={() => {
              setData([])
              setSellingType('calls')
            }}
          >
            Selling Calls
          </button>
        </div>
        <TableActionControls
          page='WheelIncome'
          title={sellingType === 'puts' ? 'Add new put' : 'Add new call'}
          icon={<MdAdd className="w-5 h-5" />}
          newRow={newRow}
          handleSave={handleWheelIncomeSave}
          data={data}
          token={token}
          setData={setData}
          setNewRow={setNewRow}
          setSort={setSort}
          logout={logout}
          fetchData={fetchData}
          table={table}
          WheelType={sellingType}
        />
      </div>

      <div className='my-2 overflow-x-auto min-h-[24rem]'>
        <div className='py-1 align-middle inline-block '>
          <div className='shadow  border-b border-gray-200  rounded-2xl'>
            <div className=' rounded-t-3xl'>
              <table
                id='tableContainer'
                style={{
                  width: table.getCenterTotalSize(),
                }}
                className=' divide-y divide-gray-200 table'
              >
                <TableHeader
                  headerGroups={table.getHeaderGroups()}
                  screenSize={screenSize}
                  calculateLeftOffset={calculateLeftOffset}
                  columnVisibility={columnVisibility}
                  newRow={newRow}
                  editableColumns={editableColumns}
                  currentColor={currentColor}
                />
                <TableBody
                  data={data}
                  table={table}
                  screenSize={screenSize}
                  newRow={newRow}
                />
                {/* <TableFooter footerGroups={table.getFooterGroups()} /> */}
              </table>
            </div>
          </div>
        </div>
      </div>
      {sellingType === 'puts' &&
        <CashRequirements data={data} />}
    </div>
  );
}

export default WheelIncome;
