import React, { useEffect, useRef, useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';
import { useStateCtx } from '../context/appContext';
import {
  NumberCell,
  handleDeleteConfirm,
  handlePortfolioSave,
  toggleDecayColumns,
} from '../utils/tableHandlers';
import { fetchTradeById, fetchTrades, getBuyingPower } from '../services/tradesServices';
import { useAuth } from '../context/auth';
import {
  ConfirmationModalContent,
  getTradeStatus,
} from '../utils/tableHelpers';
import { calculateFinalPL, calculateLeftOffset, calculateTradeSums, calculateWidth, formatNumber } from '../utils/mathHelpers';
import { BsQuestionOctagon } from 'react-icons/bs';
import { guidedPortfolio } from '../constants/guidedTutorialData';
import { guidedTutorialHandler } from '../utils/GuidedTutorialFun';
import { createNewPortfolioTrade } from '../constants/newRowsData';
import TableHeader from '../components/Table/TableHeader';
import TableBody from '../components/Table/TableBody';
import TableFooter from '../components/Table/TableFooter';
import SearchAndColumnToggle from '../components/TableToolBar/SearchAndColumnToggle';
import FilterControls from '../components/TableToolBar/FilterControls';
import TableActionControls from '../components/TableToolBar/TableActionControls';
import { MdOutlinePlaylistAdd } from 'react-icons/md';
import { PortfolioColumns } from '../constants/PortfolioColumns';

function Portfolio() {
  // CONTEXT
  const { token, logout, visitedPages, markPageAsVisited, visitedPagesLoading } = useAuth();
  const { screenSize, activeMenu, currentColor } = useStateCtx();
  // STATES
  const [data, setData] = useState([]); // use this as your data source
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [contextMenuState, setContextMenuState] = useState(false);
  const [statusFilter, setStatusFilter] = useState('all');  // ALL, ACTIVE, CLOSED, etc.
  const [colorsKeyMapState, setColorsKeyMapState] = useState(false)
  const [columnVisibility, setColumnVisibility] = useState({
    priceAt60DecayBuyout: false,
    valueAt60DecayBuyout: false,
    priceAt70DecayBuyout: false,
    valueAt70DecayBuyout: false,
    priceAt80DecayBuyout: false,
    valueAt80DecayBuyout: false,
    priceAt90DecayBuyout: false,
    valueAt90DecayBuyout: false,
    priceAt100DecayBuyout: false,
    valueAt100DecayBuyout: false,
  });
  const [columnOrder, setColumnOrder] = useState([]);
  const [sort, setSort] = useState(true);
  const [newRow, setNewRow] = useState(false);
  const [decay, setDecay] = useState([]);
  const [footerSums, setFooterSums] = useState({
    totalOptionsValue: 0,
    totalIfAssigned: 0,
    totalFinalPL: 0,
    buyingPower: 0,
  });


  useEffect(() => {
    const sums = calculateTradeSums(data, getTradeStatus, calculateFinalPL);
    setFooterSums({
      totalOptionsValue: formatNumber(sums.totalOptionsValue),
      totalIfAssigned: formatNumber(sums.totalIfAssigned),
      totalFinalPL: formatNumber(sums.totalFinalPL),
      // buyingPower: formatNumber(fetchBuyingPower)
    });
    // fetchBuyingPower()
  }, [data, getTradeStatus, calculateFinalPL]);


  const handleDecay = (selectedDecay, allColumns) => {
    setDecay((prevDecay) => {
      let updatedDecay;

      if (prevDecay.includes(selectedDecay)) {
        updatedDecay = prevDecay.filter((d) => d !== selectedDecay);
      } else {
        updatedDecay = [...prevDecay, selectedDecay];
      }

      toggleDecayColumns(updatedDecay, allColumns);
      // setIsDecayVisible(newVisibility);

      return updatedDecay;
    });
  };

  const handleDelete = (trade) => {
    setModalState({ isOpen: true, trade });
  };
  const useSkipper = () => {
    const shouldSkipRef = React.useRef(true);
    const shouldSkip = shouldSkipRef.current;

    // Wrap a function with this to skip a pagination reset temporarily
    const skip = React.useCallback(() => {
      shouldSkipRef.current = false;
    }, []);

    React.useEffect(() => {
      shouldSkipRef.current = true;
    });

    return [shouldSkip, skip];
  };
  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();


  const updateData = async ({ rowIndex, rowId, columnId, value }) => {
    skipAutoResetPageIndex();

    try {
      // Determine if manual override should be set
      const isManualOverride = columnId === 'finalPL';

      // Immediately update row with new value for specified columnId
      setData((old) =>
        old.map((row, index) =>
          index === rowIndex
            ? {
              ...row,
              [columnId]: value,
              ...(isManualOverride && { finalPLOverride: true }), // Set manual override flag if applicable
            }
            : row
        )
      );

      // If columnId requires fetching updated trade data, do so
      if (['ticker', 'strike', 'tradeDate', 'expirationDate', 'premium', 'numberOfOptions'].includes(columnId) && rowId) {
        const updatedTrade = await fetchTradeById(rowId, token);
        const fieldsToUpdate = [
          'currentPrice', 'stockPriceAtTrade', 'name',
          'sector', 'industry', 'employees', 'marketCap',
          'peRatio', 'averageVolume', 'currentPrice', 'stockPriceAtTrade'
        ];

        setData((old) =>
          old.map((row, index) =>
            index === rowIndex
              ? {
                ...row,
                ...fieldsToUpdate.reduce((acc, field) => {
                  acc[field] = updatedTrade[field];
                  return acc;
                }, {}),
                ...(isManualOverride && { finalPLOverride: updatedTrade.finalPLOverride }), // Sync manual override flag
              }
              : row
          )
        );
      }
    } catch (error) {
      if (error.response && error.response.data.error === 'Invalid token.') {
        logout();
      }
      // Handle other potential errors here
      console.error('Error updating data:', error);
    }
  };


  const columns = PortfolioColumns({ handleDelete, updateData, columnVisibility, footerSums });
  const defaultColumn = {
    cell: (info) => {
      return (
        <NumberCell
          key={info.row.id}
          updateData={updateData}
          row={info.row}
          getValue={info.row.getValue}
          columnId={info.column.id}
          page={'portfolio'}
        />
      );
    },
  };

  const table = useReactTable({
    data,
    defaultColumn,
    columns,
    state: {
      columnVisibility,
      columnOrder,
      sorting,
      globalFilter,
    },
    enableSorting: sort,
    onGlobalFilterChange: setGlobalFilter,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    columnResizeMode: 'onChange',
    enableColumnResizing: true,
    onColumnOrderChange: setColumnOrder,
    autoResetPageIndex,
    meta: {
      updateData,
      addRow: () => {
        const newRow = createNewPortfolioTrade();
        setData((oldData) => [newRow, ...oldData]);
        setNewRow(true);
      },
    },
  });
  const [modalState, setModalState] = useState({ isOpen: false, trade: null });


  const originalDataRef = useRef([]);


  const fetchBuyingPower = async () => {
    try {
      const { globalBuyingPower } = await getBuyingPower(token);
      if (globalBuyingPower !== undefined) { // Check if the fetched value is valid
        setFooterSums((prev) => ({
          ...prev,
          buyingPower: formatNumber(globalBuyingPower, 0) // Update only if bP is valid
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchData = async () => {
    try {
      const trades = await fetchTrades(token);
      originalDataRef.current = trades || [];
      setData(originalDataRef.current);
      fetchBuyingPower()
    } catch (error) {
      console.error('Error fetching trades:', error);
      if (error.response.data.error === 'Invalid token.') logout();
    }
  };

  useEffect(() => {

    fetchData();
  }, [token]);

  // For subsequent data filtering
  useEffect(() => {
    if (statusFilter === 'all') {
      setData(originalDataRef.current);
    } else {
      const filteredData = originalDataRef.current.filter((row) => getTradeStatus(row).value === statusFilter);

      setData(filteredData);
    }
  }, [statusFilter]);

  const pageName = 'portfolio'; // Define a unique name for this page
  useEffect(() => {
    if (!visitedPagesLoading && token) {
      // Check if the page has not been visited and trigger the tutorial
      if (!visitedPages[pageName] || visitedPages[pageName] === false) {


        guidedTutorialHandler(guidedPortfolio);
        markPageAsVisited(pageName); // Mark the page as visited
      }
    }
  }, [visitedPages, markPageAsVisited, pageName, visitedPagesLoading]); // Dependencies for useEffect




  const editableColumns = [
    'color',
    'putCall',
    'ticker',
    'tradeDate',
    'expirationDate',
    'strike',
    'tradeType',
    'premium',
    'numberOfOptions',
    'stockPriceAtTrade',
    'premiumMarketPrice',
    'closingDate',
    'closingTradeType',
    'closingPremium',
    'finalPL',
  ];

  // Render the table
  return (
    <div
      style={{ width: calculateWidth(activeMenu, screenSize) }}
      className='flex flex-col  bg-light-background p-4  h-full mx-auto '
    >
      <div className='flex w-full items-center justify-between mb-6 mt-2 gap-4'>

        <h1 className='text-3xl font-semibold  text-light-text'>
          Portfolio
        </h1>
        <button className='animate-scale-once mr-4 ' onClick={() => guidedTutorialHandler(guidedPortfolio)}><BsQuestionOctagon className='w-9 h-9 fill-orange-500' /></button>
      </div>
      <ConfirmationModalContent
        isOpen={modalState.isOpen}
        title='Delete Trade'
        message='Are you sure you want to delete this trade? This action cannot be undone.'
        onConfirm={() =>
          handleDeleteConfirm({
            modalState,
            setModalState,
            token,
            setData,
            logout,
          })
        }
        onCancel={() => {
          setModalState({ isOpen: false, trade: null });
        }}
      />

      <div id='toolbar' className="flex flex-col lg:flex-row justify-between items-center text-md mb-4 bg-gradient-to-r from-gray-200 to-gray-300 px-4 py-2 rounded-lg shadow-md transition-all duration-300 ease-in-out" >

        <SearchAndColumnToggle
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          contextMenuState={contextMenuState}
          setContextMenuState={setContextMenuState}
          table={table}
        />
        <FilterControls
          handleDecay={handleDecay}
          selectedDecay={decay}
          columns={table.getAllColumns()}
          setStatusFilter={setStatusFilter}
          statusOptions={['all', 'active', 'closed', 'pending']}
        />
        <TableActionControls
          title={'Add a Trade'}
          icon={<MdOutlinePlaylistAdd className="w-5 h-5" />}
          newRow={newRow}
          handleSave={handlePortfolioSave}
          data={data}
          token={token}
          setData={setData}
          setNewRow={setNewRow}
          setSort={setSort}
          logout={logout}
          fetchData={fetchData}
          setColorsKeyMapState={setColorsKeyMapState}
          colorsKeyMapState={colorsKeyMapState}
          table={table}
        />

      </div>

      <div className='my-2 overflow-x-auto min-h-[34rem]'>
        <div className='py-1 align-middle inline-block  '>
          <div className='shadow  border-b border-gray-200  rounded-2xl'>
            <div className=' rounded-t-3xl '>
              <table
                id='tableContainer'
                style={{
                  width: table.getCenterTotalSize(),
                }}
                className=' divide-y  divide-gray-200 table rounded-2xl'
              >
                <TableHeader
                  headerGroups={table.getHeaderGroups()}
                  screenSize={screenSize} // This value should come from your state or context
                  calculateLeftOffset={calculateLeftOffset} // This function should be defined or imported
                  columnVisibility={columnVisibility} // This state should be managed in the parent component
                  newRow={newRow} // This state should be managed in the parent component
                  editableColumns={editableColumns} // This array should be defined or imported
                  currentColor={currentColor} // This value should come from your state or context
                  page={'portfolio'}
                />
                <TableBody
                  data={data}
                  table={table}
                  screenSize={screenSize} // Pass the screenSize state
                  getTradeStatus={getTradeStatus} // Pass the getTradeStatus function
                  newRow={newRow} // Pass the newRow state
                  page={'portfolio'}
                />
                <TableFooter
                  footerGroups={table.getFooterGroups()}
                />
              </table>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default Portfolio;
