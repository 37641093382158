import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { MdOutlineCopyAll } from "react-icons/md";
import { useAuth } from '../context/auth';
import { searchTickers } from '../services/searchedTickers';

import { toast } from 'react-toastify';
const categories = ['All', 'Stock', 'ETF', 'Index'];

const TickerSearchBar = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState({
    stock: [],
    ETF: [],
    index: [],
  });
  const [selectedCategory, setSelectedCategory] = useState('All'); // Default to 'All'
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Track dropdown visibility
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useAuth();

  const inputRef = useRef(null); // Reference to input for positioning
  const dropdownRef = useRef(null); // Reference to the dropdown

  // Function to handle API call
  const fetchTickers = async (searchQuery) => {
    setIsLoading(true);
    const tickerResults = await searchTickers(searchQuery, token); // This now calls your backend
    setResults(tickerResults); // This assumes the API returns an object with keys like stock, ETF, etc.
    setIsLoading(false);
  };

  // Debounce the search query to avoid too many API calls
  useEffect(() => {
    if (query.length > 2) {
      const delayDebounceFn = setTimeout(() => {
        fetchTickers(query);
        setIsDropdownOpen(true); // Open dropdown when search starts
      }, 500); // 500ms debounce

      return () => clearTimeout(delayDebounceFn); // Clean up timeout
    } else {
      setIsDropdownOpen(false); // Close dropdown if query is too short
    }
  }, [query]);

  // Handle clicks outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && inputRef.current && !inputRef.current.contains(event.target)) {
        setIsDropdownOpen(false); // Close dropdown if click happens outside
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, inputRef]);

  // Function to copy ticker to clipboard
  const copyToClipboard = (ticker) => {
    navigator.clipboard.writeText(ticker).then(() => {
      toast.info('Copied');
    });
    // setIsDropdownOpen(false); // Close dropdown when item is copied
  };

  // Filter results based on the selected category
  const getFilteredResults = () => {
    if (selectedCategory === 'All') {
      // Combine results from all categories
      return [
        ...results.stock,
        ...results.ETF,
        ...results.index,
        // ...results.mutual_fund,
        // ...results.currency,
        // ...results.futures,
      ];
    }
    // Return results of the selected category (lowercase key matches the results object structure)
    return results[selectedCategory.toLowerCase()] || [];
  };

  const filteredResults = getFilteredResults();

  // Render the dropdown using a portal
  const renderDropdown = () => {
    if (!isDropdownOpen) return null;

    // Get the position of the input field for accurate placement
    const inputRect = inputRef.current.getBoundingClientRect();

    return ReactDOM.createPortal(
      <div
        ref={dropdownRef}
        className="absolute z-[10000] w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-80 overflow-auto"
        style={{
          top: `${inputRect.bottom + window.scrollY}px`, // Adjust for page scroll
          left: `${inputRect.left}px`,
          width: `${inputRect.width}px`,
        }}
      >
        {/* Category Tabs */}
        <div className="sticky top-0 bg-white p-2 border-b border-gray-200 z-[10000]">
          <div className="flex   justify-center">
            {categories.map((category) => (
              <button
                key={category}
                className={`px-4 py-2 border-b-2 ${selectedCategory === category ? 'border-blue-500 text-blue-500' : 'border-transparent text-gray-500'} focus:outline-none whitespace-nowrap`}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </button>
            ))}
          </div>
        </div>

        {isLoading && (
          <p className="text-sm text-gray-500 p-4 text-center">Loading...</p>
        )}

        {/* Search Results */}
        {filteredResults.length > 0 ? filteredResults.map((result) => (
          <li
            key={result.symbol}
            className="flex justify-between items-center px-4 py-3 hover:bg-gray-100 cursor-pointer"
            onClick={() => copyToClipboard(`${result.symbol}`)}
          >
            <div className="flex flex-col space-y-1">
              <div className="flex items-center space-x-2">
                <span className="font-semibold text-lg">{result.symbol}</span>
                <span className="text-sm text-gray-500">{result.country_code}</span>
              </div>
              <span className="text-sm text-gray-600">{result.name}</span>
            </div>
            <MdOutlineCopyAll className="w-5 h-5 text-gray-500 hover:text-blue-500" />
          </li>
        )) : <p className='text-center px-auto py-4 font-bold text-gray-700'>No Symbol has been found</p>}
      </div>,
      document.body // Render the dropdown directly in the body
    );
  };

  return (
    <div className="relative w-full max-w-lg mx-auto z-[9999]">
      <input
        type="text"
        ref={inputRef} // Attach the ref here
        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder="Search for ticker symbols..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onFocus={() => setIsDropdownOpen(true)} // Open dropdown on focus
      />

      {/* Render dropdown using a portal */}
      {renderDropdown()}
    </div>
  );
};

export default TickerSearchBar;
