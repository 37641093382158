import React from 'react';

const AboutUs = () => {
  return (
    <section className="  py-16">
      <div className="container mx-auto px-6 md:px-12 lg:px-24">
        {/* Title Section */}
        <div className="text-center mb-16">
          <h1 className="text-5xl font-extrabold text-gray-900 leading-tight mb-4">
            Welcome to <span className="text-primary">Wheel Options Strategy Platform</span>
          </h1>
          <p className="text-xl text-gray-700 max-w-2xl mx-auto">
            A powerful web platform designed to simplify your trading strategies with real-time data, automation, and full portfolio control.
          </p>
        </div>

        {/* Platform Features Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 mb-16">
          <div className="bg-white shadow-lg rounded-lg p-8 text-center transition-transform transform hover:scale-105">
            <div className="mb-4 text-primary">
              <svg className="w-12 h-12 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
            </div>
            <h3 className="text-2xl font-bold text-gray-900 mb-2">Real-Time Data</h3>
            <p className="text-gray-600">
              Gain instant access to market data and trends, helping you make smarter, faster trading decisions.
            </p>
          </div>

          <div className="bg-white shadow-lg rounded-lg p-8 text-center transition-transform transform hover:scale-105">
            <div className="mb-4 text-primary">
              <svg className="w-12 h-12 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m-3-3l-3-3m12-3v12" />
              </svg>
            </div>
            <h3 className="text-2xl font-bold text-gray-900 mb-2">Automated Insights</h3>
            <p className="text-gray-600">
              Save time and reduce errors with fully automated tools that track and calculate your trades.
            </p>
          </div>

          <div className="bg-white shadow-lg rounded-lg p-8 text-center transition-transform transform hover:scale-105">
            <div className="mb-4 text-primary">
              <svg className="w-12 h-12 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 20h10l1-1V9H6v10l1 1z" />
              </svg>
            </div>
            <h3 className="text-2xl font-bold text-gray-900 mb-2">Cloud-Based Access</h3>
            <p className="text-gray-600">
              Access your portfolio anywhere, anytime, on any device. Flexibility and control at your fingertips.
            </p>
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center mb-16">
          <a
            href="https://wheeloptionstraders.com/"
            className="inline-block bg-[#3e64de] hover:bg-primary-dark text-white font-semibold py-4 px-12 rounded-full text-lg shadow-lg transition-transform transform hover:scale-105"
          >
            Access your wheeloptionstraders account
          </a>
        </div>

        {/* Support and Contact */}
        <div className="bg-white p-12 rounded-lg shadow-lg text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Need Support?</h2>
          <p className="text-lg text-gray-700 mb-4">
            Reach out to us if you have any questions or need help. We are here to support you.
          </p>
          <a
            href="https://wheeloptionstraders.com/contact/"
            className="text-primary font-semibold text-lg underline hover:text-primary-dark transition"
          >
            Contact Us
          </a>
        </div>

        {/* Copyright Agreement */}
        <div className="bg-white shadow-lg rounded-lg p-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4 text-center">Copyright Agreement</h2>
          <p className="text-lg text-gray-700 mb-4">
            By using this platform, you agree not to share, distribute, or sell access to the platform or any related materials. Unauthorized redistribution or sharing of the platform’s content or features is strictly prohibited.
          </p>
          <p className="text-lg text-gray-700 mb-4">
            Customizations or alterations made within the platform do not grant the right to redistribute any content. Please ensure all changes comply with our terms of use.
          </p>
          <p className="text-lg text-gray-700 mb-4">
            This platform and its content are protected by copyright laws. All rights reserved.
          </p>
          <p className="text-lg text-gray-700 text-center font-bold">&copy; 2023 Wheel Options Traders</p>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
