import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

const Tooltip = ({
  icon,
  content,
  type,
  position = 'bottom',
  color = 'bg-red-600',
  tooltipClass = '',
  tooltipStyle = {},
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const iconRef = useRef(null);
  const tooltipRef = useRef(null);

  useEffect(() => {
    const handleResize = () => setShowTooltip(false);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showTooltip &&
        iconRef.current &&
        !iconRef.current.contains(event.target) &&
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target)
      ) {
        setShowTooltip(false);
      }
    };

    if (showTooltip) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showTooltip]);

  const handleToggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  let tooltipStyleWithPosition = {};

  if (showTooltip && iconRef.current) {
    const rect = iconRef.current.getBoundingClientRect();

    const isSmallScreen = window.innerWidth <= 768;

    switch (position) {
      case 'bottom':
        tooltipStyleWithPosition = {
          ...tooltipStyle,
          position: 'absolute',
          top: `${rect.bottom + window.scrollY}px`,
          left: isSmallScreen ? '0px' : `${rect.left}px`,
          maxWidth: isSmallScreen ? '100%' : 'auto',
        };
        break;
      // Add cases for other positions as needed
      default:
        break;
    }
  }

  const renderTooltip = () => {
    if (!showTooltip) return null;

    return ReactDOM.createPortal(
      <div
        id='tooltip-description'
        role='tooltip'
        className={`z-[9999] p-2 text-sm text-white rounded-md shadow-lg transition-opacity duration-300 ease-in ${color} ${tooltipClass}`}
        style={tooltipStyleWithPosition}
        ref={tooltipRef}
      >
        {content}
      </div>,
      document.body // Render the tooltip directly in the body
    );
  };

  return (
    <div
      className={`cursor-pointer flex items-center justify-center`}
      onClick={handleToggleTooltip}
      ref={iconRef}
    >
      {icon}
      {renderTooltip()}
    </div>
  );
};

export default Tooltip;
