import React from 'react';
import { flexRender } from '@tanstack/react-table';
import { FiArrowUp, FiArrowDown } from 'react-icons/fi';
import { CiEdit } from 'react-icons/ci';
import { getBgColor } from '../../utils/stylingHelpers';
;

const TableHeader = ({ headerGroups, screenSize, calculateLeftOffset, columnVisibility, newRow, editableColumns, currentColor, page }) => {
  return (
    <thead className='bg-gray-50 sticky top-0 z-40'>
      {headerGroups.map((headerGroup, i) => (
        <tr key={headerGroup.id} className=''>
          {headerGroup.headers.map((header, headerIndex) => {
            const isStickyColumn = ['Options', 'delete', 'color', 'putCall', 'ticker', 'name'].includes(header.column.id) && page === 'portfolio' && screenSize > 900;
            const precedingColumns = headerGroup.headers.slice(0, headerIndex);
            const leftOffset = header.column.id === 'Options' ? 0 : (isStickyColumn ? calculateLeftOffset(precedingColumns) : undefined);

            return (
              <th
                key={header.id}
                scope='col'
                onClick={header.column.getToggleSortingHandler()}
                colSpan={header.colSpan}
                style={{
                  minWidth: header.getSize(),
                  left: ['Options', 'delete'].includes(header.column.id) && screenSize > 900 ? 0 : leftOffset ? `${leftOffset}px` : undefined,
                }}
                className={`mx-0 p-0 bg-gray-50 w-full  ${isStickyColumn && screenSize > 900 ? 'sticky z-10' : 'relative'} ${header.column.getCanSort() ? 'cursor-pointer select-none' : ''}`}
              >
                <div className={`flex justify-center whitespace-nowrap bg-gray-50 border-r border-b items-center ${Object.values(columnVisibility).some(value => value === true) ? 'h-[28]' : ''}
                 ${header.id === '2_closingDate_closingDate' && header.isPlaceholder && 'border-l-[6px] border-l-gray-400'}
                  ${header.id === '2_finalPL_finalPL' && header.isPlaceholder && 'border-r-[6px] border-r-gray-400'}`}>
                  {header.isPlaceholder ? null : (
                    <div className={`flex w-full justify-center items-center ${page === 'breakEven' ? '' : getBgColor(header.column.columnDef.id)}`}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: <FiArrowUp className='ml-1 w-4 h-4' style={{ color: currentColor }} />,
                        desc: <FiArrowDown className='ml-1 w-4 h-4' style={{ color: currentColor }} />,
                      }[header.column.getIsSorted()] ?? null}
                    </div>
                  )}
                </div>
                <div
                  onMouseDown={header.getResizeHandler()}
                  onTouchStart={header.getResizeHandler()}
                  className={`absolute right-0 top-0 w-1 h-full bg-black cursor-col-resize select-none touch-none opacity-0 hover:opacity-100`}
                />
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
};

export default TableHeader;
