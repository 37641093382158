import { MdRemoveCircleOutline } from 'react-icons/md';
import {
  ColorCell,
  DateCell,
  DropdownCell,
  FinalPLCell,
  MarketCapCell,
  StatusCell,
  TickerCell,
  TickerInput,
} from '../utils/tableHandlers';
import { useMemo } from 'react';
import { useAuth } from '../context/auth';
import Tooltip from '../components/Tooltip';
import { calculateDaysToExpiration, formatNumber } from '../utils/mathHelpers';
import { HiQuestionMarkCircle } from 'react-icons/hi';
import { getTradeStatus } from '../utils/tableHelpers';
import { differenceInCalendarDays, format, startOfToday } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { getBgColor } from '../utils/stylingHelpers';


export const PortfolioColumns = ({
  handleDelete,
  updateData,
  columnVisibility,
  footerSums,
}) => {
  const { token } = useAuth();
  const calculateDecayedBuyoutPremium = (initialPremium, decayPercentage) => {
    return initialPremium - (initialPremium * decayPercentage) / 100;
  };

  const decayPercentages = [60, 70, 80, 90, 100];
  const getDecayBgColor = (percent) => {
    const colorMap = {
      60: 'bg-yellow-200',
      70: 'bg-orange-200',
      80: 'bg-blue-200',
      90: 'bg-green-200',
      100: 'bg-red-200',
    };

    return colorMap[percent] || '';
  };

  const generateDecayColumns = () => {
    const columns = [];
    const firstVisibleDecay = Object.keys(columnVisibility).reduce(
      (acc, key) => {
        const isPriceColumnVisible = columnVisibility[key];
        if (isPriceColumnVisible && acc === null) {
          // Extract the percentage from the key (e.g., "priceAt90DecayBuyout" -> 90)
          const match = key.match(/(\d+)DecayBuyout/);
          if (match) return parseInt(match[1], 10);
        }
        return acc;
      },
      null
    );
    decayPercentages.forEach((percent, index) => {
      const bgColor = getDecayBgColor(percent);
      const isFirstVisibleColumn = percent === firstVisibleDecay;
      const leftBorderStyle = isFirstVisibleColumn
        ? 'border-l-[6px] border-gray-400'
        : ''; // Adjust the border color as needed
      columns.push({
        id: `decay${percent}`,
        header: (
          <h6
            className={` px-4 py-1 text-center  whitespace-nowrap text-ellipsis w-full ${bgColor} text-xs font-medium ${leftBorderStyle} text-gray-500 uppercase tracking-wider`}
          >
            {`${percent}% Decay`}
          </h6>
        ),
        accessorKey: `decay${percent}`,
        enableHiding: true,
        columns: [
          {
            id: `priceAt${percent}DecayBuyout`,
            accessorKey: `priceAt${percent}DecayBuyout`,
            header: (
              <h6
                className={` px-4 py-1 text-center whitespace-nowrap text-ellipsis w-full bg-gray-100 text-xs font-medium  ${leftBorderStyle} text-gray-500 uppercase tracking-wider`}
              >
                price
              </h6>
            ),
            cell: (info) => {
              const initialPremium = info.row.getValue('premium');
              return (
                <span
                  className={`w-full  ${leftBorderStyle} px-4 py-1 text-end bg-gray-100`}
                >{`$ ${formatNumber(
                  calculateDecayedBuyoutPremium(initialPremium, percent)
                )}`}</span>
              );
            },
            size: 125,
            maxSize: 200,
            minSize: 100,
          },
          {
            id: `valueAt${percent}DecayBuyout`,
            accessorKey: `valueAt${percent}DecayBuyout`,
            header: (
              <h6
                className={` px-4 py-1  text-center whitespace-nowrap text-ellipsis w-full ${bgColor} text-xs font-medium text-gray-500 uppercase tracking-wider`}
              >
                value
              </h6>
            ),
            // is
            cell: (info) => {
              const decayedPremium = calculateDecayedBuyoutPremium(
                info.row.getValue('premium'),
                percent
              );
              return (
                <span
                  className={`w-full px-4 py-1 text-end ${bgColor}`}
                >{`$ ${formatNumber(
                  (info.row.getValue('premium') - decayedPremium) *
                  100 *
                  info.row.getValue('numberOfOptions')
                )}`}</span>
              );
            },
            size: 125,
            maxSize: 200,
            minSize: 100,
          },
        ],
      });
    });

    return columns;
  };

  return useMemo(
    () => [
      {
        id: 'Options',
        accessorKey: 'Options',
        header: (
          <h6 className='px-4 py-1 w-full text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
            Options
          </h6>
        ),

        columns: [
          {
            id: 'delete',
            accessorKey: 'delete',
            header: <h6 className='block w-full h-6'></h6>,
            cell: (info) => (
              <>
                {info.row.original._id && (
                  <button
                    className='px-2 py-1 w-full flex justify-center items-center'
                    onClick={() => handleDelete(info.row)}
                  >
                    <MdRemoveCircleOutline className='w-5 h-5 fill-red-600' />
                  </button>
                )}
              </>
            ),
            // width: 10,
            size: 50,
            maxSize: 100,
            minSize: 30,
            enableResizing: true,
            // disableFilters: true,
            // disableSortBy: true,
          },
          {
            id: 'color',
            header: <h6 className='block w-full h-6'></h6>,
            accessorKey: 'color',
            cell: (info) => {
              return (
                <ColorCell
                  updateData={updateData}
                  row={info.row}
                  columnId={'color'}
                  token={token}
                />
              );
            },
            size: 50,
            maxSize: 100,
            minSize: 30,
            enableResizing: true,
            // disableFilters: true,
            // enableResizing: false,
            // disableSortBy: true,
          },
          {
            id: 'putCall',
            header: (
              <h6
                id='putCall'
                className='px-4 py-1 w-full text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '
              >
                PUT/Call
              </h6>
            ),
            accessorKey: 'putCall',
            cell: (info) => (
              <DropdownCell
                row={info.row}
                options={[
                  { value: 'put', label: 'Put' },
                  { value: 'call', label: 'Call' },
                ]}
                updateData={updateData}
                columnId={'putCall'}
                page='portfolio'
              />
            ),
            size: 100,
            maxSize: 150,
            minSize: 80,
          },

          {
            id: 'ticker',
            header: (
              <h6
                id='tickerHeader'
                className='px-4 py-1 w-full sticky text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '
              >
                ticker
              </h6>
            ),
            accessorKey: 'ticker',
            cell: (info) => {
              return (
                <TickerCell
                  updateData={updateData}
                  columnId={'ticker'}
                  row={info.row}
                  page='portfolio'
                />
              );
            },
            size: 100,
            maxSize: 150,
            minSize: 90,
          },
          {
            id: 'name',
            header: (
              <h6 className='px-4 py-1 w-full text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
                Company Name
              </h6>
            ),
            accessorKey: 'name',
            cell: (info) => {
              // Assume you have a way to get the expirationDate from your data
              const expirationDate = info.row.original.expirationDate;
              const { value: tradeStatus } = getTradeStatus(info.row);
              // Determine the background color based on the expiration status
              let bgColorClass = 'bg-transparent';
              if (tradeStatus !== 'closed') {
                const expirationStatus =
                  calculateDaysToExpiration(expirationDate);
                if (expirationStatus === 'Expired') {
                  bgColorClass = 'bg-gray-400'; // Expired (dark gray)
                } else if (expirationStatus === 0) {
                  bgColorClass = 'bg-red-500'; // Expires today (red)
                } else if (
                  typeof expirationStatus === 'number' &&
                  expirationStatus < 7
                ) {
                  bgColorClass = 'bg-orange-300'; // Less than 7 days to expire (orange)
                }
              }
              return (
                <div
                  className={`px-4 py-1 ${bgColorClass} flex justify-between space-x-2 w-full`}
                >
                  <h6
                    className={` ${info.row.getValue('name') === 'Pending...'
                      ? 'text-gray-500 italic'
                      : info.row.getValue('name')
                        ? ''
                        : 'text-red-500 italic'
                      } flex-1 text-left `}
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      maxWidth: '100%', // Ensure max-width is set to 100% to enforce truncation
                    }}
                  >
                    {info.row.getValue('name')
                      ? info.row.getValue('name')
                      : info.row.original._id
                        ? 'Not found'
                        : ''}
                  </h6>
                  <Tooltip
                    icon={
                      <HiQuestionMarkCircle
                        className={`w-5 h-5 ${info.row.getValue('name') === 'Pending...'
                          ? 'fill-[#ffc954]'
                          : info.row.getValue('name')
                            ? 'fill-[#3e63decc]'
                            : info.row.original._id
                              ? 'fill-[#ff3737]'
                              : 'fill-[#949494]'
                          }`}
                      />
                    }
                    content={
                      info.row.getValue('name') === 'Pending...'
                        ? 'Pending...'
                        : !info.row.original._id
                          ? 'Please type a Ticker'
                          : info.row.getValue('name')
                            ? info.row.original.description
                            : 'Not found'
                    }
                    type='text'
                    color={`${info.row.getValue('name') === 'Pending...'
                      ? 'bg-[#ffc954]'
                      : info.row.getValue('name')
                        ? 'bg-[#6582e5]'
                        : info.row.original._id
                          ? 'bg-red-600'
                          : 'bg-gray-400'
                      }`}
                    tooltipClass='max-w-md whitespace-normal break-words'
                    tooltipStyle={{
                      border: `3px solid  ${info.row.getValue('name') === 'Pending...'
                        ? '#ffc924'
                        : info.row.getValue('name')
                          ? '#3e64de'
                          : info.row.original._id
                            ? '#ff3737'
                            : '#949494'
                        }`,
                      width: '300px',
                      maxHeight: '200px',
                      overflowY: 'auto',
                      zIndex: '999',
                    }}
                  />
                </div>
              );
            },
            size: 150,
            maxSize: 250,
            minSize: 120,
          },
        ],
      },

      {
        id: 'TradeDetails',
        header: (
          <h6
            className={` px-4 py-1 text-center whitespace-nowrap text-ellipsis w-full ${getBgColor(
              'TradeDetails'
            )} text-xs font-medium text-gray-500 uppercase tracking-wider`}
          >
            Trade Details
          </h6>
        ),
        columns: [
          {
            id: 'tradeDate',
            accessorKey: 'tradeDate',
            header: (
              <h6
                className={` px-4 py-1 text-center whitespace-nowrap text-ellipsis w-full ${getBgColor(
                  'tradeDate'
                )} text-xs font-medium text-gray-500 uppercase tracking-wider`}
              >
                T.Date
              </h6>
            ),
            cell: (info) => {
              return (
                <DateCell
                  updateData={updateData}
                  row={info.row}
                  columnId={'tradeDate'}
                  page='portfolio'
                />
              );
            },
            size: 130,
            maxSize: 200,
            minSize: 130,
            Footer: 'Trade Date',
          },
          {
            id: 'expirationDate',
            accessorKey: 'expirationDate',
            header: (
              <h6
                className={` px-4 py-1 text-center whitespace-nowrap text-ellipsis w-full ${getBgColor(
                  'expirationDate'
                )} text-xs font-medium text-gray-500 uppercase tracking-wider`}
              >
                Expiration Date
              </h6>
            ),
            cell: (info) => (
              <DateCell
                updateData={updateData}
                row={info.row}
                columnId={'expirationDate'}
                page='portfolio'
              />
            ),
            size: 140,
            maxSize: 200,
            minSize: 140,
          },
          {
            id: 'strike',
            accessorKey: 'strike',
            header: (
              <h6
                className={` px-4 py-1 text-center whitespace-nowrap text-ellipsis w-full ${getBgColor(
                  'strike'
                )} text-xs font-medium text-gray-500 uppercase tracking-wider`}
              >
                strike
              </h6>
            ),
            // cell: (info) => (
            //   <NumberCell
            //     value={info.row.getValue('strike')}
            //     updateData={updateData}
            //     row={info.row}
            //     getValue={info.row.getValue}
            //     rowId={info.row.original._id}
            //     rowIndex={info.row.index}
            //     columnId={'strike'}
            //     token={token}
            //   />
            // ),
            Footer: 'Strike',
            size: 100,
            maxSize: 150,
            minSize: 50,
          },
          {
            id: 'tradeType',
            accessorKey: 'tradeType',
            header: (
              <h6
                className={` px-4 py-1 text-center whitespace-nowrap text-ellipsis w-full ${getBgColor(
                  'tradeType'
                )} text-xs font-medium text-gray-500 uppercase tracking-wider`}
              >
                trade Type
              </h6>
            ),
            cell: (info) => (
              <DropdownCell
                row={info.row}
                options={[
                  { value: 'STO', label: 'STO' },
                  { value: 'STO(rolled)', label: 'STO (rolled)' },
                ]}
                updateData={updateData}
                columnId={'tradeType'}
                page='portfolio'
              />
            ),
            size: 150,
            maxSize: 200,
            minSize: 110,
            Footer: 'Trade',
          },
          {
            id: 'premium',
            accessorKey: 'premium',
            header: (
              <h6
                className={` px-4 py-1 text-center whitespace-nowrap text-ellipsis w-full ${getBgColor(
                  'premium'
                )} text-xs font-medium text-gray-500 uppercase tracking-wider`}
              >
                premium
              </h6>
            ),
            size: 100,
            maxSize: 150,
            minSize: 50,
            Footer: 'Premium',
          },
          {
            id: 'numberOfOptions',
            accessorKey: 'numberOfOptions',
            header: (
              <h6
                className={` px-4 py-1 text-center whitespace-nowrap text-ellipsis w-full ${getBgColor(
                  'numberOfOptions'
                )} text-xs font-medium text-gray-500 uppercase tracking-wider`}
              >
                Nb Options
              </h6>
            ),
            size: 125,
            maxSize: 150,
            minSize: 100,
            Footer: 'Number of Options',
          },
          {
            id: 'totalOptionsValue',
            accessorKey: 'totalOptionsValue',
            header: (
              <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
                Options Value
              </h6>
            ),
            cell: (info) => (
              <span className='w-full px-4 py-1 flex items-center justify-end text-end'>
                {`$ ${formatNumber(
                  info.row.getValue('numberOfOptions') *
                  info.row.getValue('premium') *
                  100
                )}`}
              </span>
            ),
            size: 125,
            maxSize: 150,
            minSize: 100,
            footer: () => (
              <div className='  flex flex-col text-sm'>
                <div className='px-4 py-1 flex justify-end whitespace-nowrap items-center text-end font-medium '>
                  {`$ ${footerSums.totalOptionsValue}`}
                </div>
                <div
                  className={` relative   flex justify-end items-center text-end h-[28px] font-medium `}
                >
                  <p className='px-4 py-1 absolute whitespace-nowrap  font-bold text-purple-600 bg-[#ECDFF5]'>
                    Payable IF all PUT positions assigned =
                  </p>
                </div>
                <div
                  className={` relative   flex justify-end items-center  text-end h-[28px] font-medium `}
                >
                  <p className='px-4 py-1 absolute whitespace-nowrap font-bold text-red-600 bg-[#ECDFF5]'>
                    Buying power available =
                  </p>
                </div>
              </div>
            ),
          },
          {
            id: 'totalValueIfAssigned',
            accessorKey: 'totalValueIfAssigned',
            header: (
              <h6
                className={` px-4 py-1 text-center whitespace-nowrap text-ellipsis w-full ${getBgColor(
                  'totalValueIfAssigned'
                )} text-xs font-medium text-gray-500 uppercase tracking-wider`}
              >
                TOTAL IF ASSIGNED
              </h6>
            ),
            cell: (info) => {
              return (
                <span
                  className={`w-full h-[28px] px-4 py-1 flex items-center justify-end text-end ${getTradeStatus(info.row) !== 'Closed'
                    ? getBgColor('totalValueIfAssigned')
                    : ''
                    }`}
                >
                  {info.row.getValue('putCall') === 'put'
                    ? `$ ${formatNumber(
                      info.row.getValue('numberOfOptions') *
                      100 *
                      info.row.getValue('strike'),
                      0
                    )}`
                    : null}
                </span>
              );
            },
            size: 150,
            maxSize: 175,
            minSize: 100,
            footer: () => (
              <div className='flex flex-col text-sm'>
                <div
                  className={`px-4 py-1 flex justify-end items-center text-end h-[28px] font-medium bg-[#ECDFF5]`}
                >{` `}</div>
                <div
                  id='totalValueIfAssigned'
                  className='px-4 py-1 flex justify-end items-center text-end font-bold text-purple-600 bg-[#ECDFF5]'
                >
                  {`$ ${footerSums.totalIfAssigned}`}
                </div>
                <div className='px-4 py-1 flex justify-end items-center font-bold text-red-600 text-end  bg-[#ECDFF5]'>
                  {`$ ${footerSums.buyingPower || 0}`}
                </div>
              </div>
            ),
          },
          {
            id: 'stockPriceAtTrade',
            accessorKey: 'stockPriceAtTrade',
            header: (
              <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
                Price at Trade
              </h6>
            ),
            // cell: (info) => info.getValue(), // ====================> API
            size: 125,
            maxSize: 150,
            minSize: 100,
            Footer: 'Stock Price at Trade',
          },
          {
            id: 'currentPrice',
            accessorKey: 'currentPrice',
            header: (
              <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
                Current Price
              </h6>
            ),
            cell: (info) => (
              <span className='w-full px-4 py-1 flex items-center justify-end text-end'>
                {info.getValue() && `$ ${formatNumber(info.getValue())}`}
              </span>
            ),
            size: 125,
            maxSize: 150,
            minSize: 100,
            Footer: 'Current Stock Price',
          },
          {
            id: 'daysToExpiration',
            accessorKey: 'daysToExpiration',
            header: (
              <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
                Days to Exp
              </h6>
            ),
            cell: (info) => {
              const daysToExpiration = calculateDaysToExpiration(
                info.row.original.expirationDate
              );
              const { value: tradeStatus } = getTradeStatus(info.row); // Retrieve the tradeStatus for the row
              let bgColorClass = 'bg-transparent'; // Default background
              // Apply background color based on tradeStatus and daysToExpiration
              if (tradeStatus !== 'closed') {
                if (daysToExpiration === 'Expired') {
                  bgColorClass = 'bg-gray-400'; // Expired (dark gray)
                } else if (daysToExpiration === 0) {
                  bgColorClass = 'bg-red-500'; // Expires today (red)
                } else if (
                  typeof daysToExpiration === 'number' &&
                  daysToExpiration < 7
                ) {
                  bgColorClass = 'bg-orange-300'; // Less than 7 days to expire (orange)
                }
              }

              return (
                <div
                  className={`px-4 py-1 ${bgColorClass} flex items-center justify-center w-full`}
                >
                  <span className='text-sm'>
                    {daysToExpiration === 'Expired'
                      ? 'Expired'
                      : `${daysToExpiration} days`}
                  </span>
                </div>
              );
            },
            size: 125,
            maxSize: 150,
            minSize: 100,
            Footer: 'Days to Expiration',
          },

          {
            id: 'premiumMarketPrice',
            accessorKey: 'premiumMarketPrice',
            header: (
              <h6
                id='premiumMarketPrice'
                className={` px-4 py-1 text-center whitespace-nowrap text-ellipsis w-full ${getBgColor(
                  'premiumMarketPrice'
                )} text-xs font-medium text-gray-500 uppercase tracking-wider`}
              >
                PREMIUM MKT PRICE
              </h6>
            ),
            Footer: 'Premium Market Price',
            size: 150,
            maxSize: 200,
            minSize: 150,
          },
          {
            id: 'currentPL',
            accessorKey: 'currentPL',
            header: (
              <h6
                id='currentPL'
                className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '
              >
                Current P&L
              </h6>
            ),
            cell: (info) => {
              const numberOfOptions = info.row.getValue('numberOfOptions');
              const premiumPaid = info.row.getValue('premium'); // This is the initial premium paid per option
              const marketPrice = info.row.getValue('premiumMarketPrice'); // This is the current market price per option

              // Calculate the current P&L
              const currentPL =
                numberOfOptions * premiumPaid * 100 -
                marketPrice * numberOfOptions * 100;

              // (marketPrice - premiumPaid) * numberOfOptions * 100; // This assumes that 'premium' and 'premiumMarketPrice' are per-option values

              let bgColor = 'bg-transparent'; // Default background color

              // Iterate through the decay percentages to find which threshold is exceeded by currentPL
              let decayThresholdExceeded;
              for (let i = decayPercentages.length - 1; i >= 0; i--) {
                const percent = decayPercentages[i];
                const decayedPremiumValue = calculateDecayedBuyoutPremium(
                  premiumPaid,
                  percent
                );
                const decayValue =
                  (premiumPaid - decayedPremiumValue) * numberOfOptions * 100;

                // Check if the current P&L exceeds the decay value
                if (currentPL >= decayValue) {
                  decayThresholdExceeded = percent;
                  break; // Found the highest decay threshold exceeded by currentPL
                }
              }

              // If a decay threshold has been exceeded, set the corresponding background color
              if (decayThresholdExceeded !== undefined) {
                bgColor = getDecayBgColor(decayThresholdExceeded);
              }

              return (
                <span
                  className={`w-full px-4 py-1 flex items-center justify-end text-end ${bgColor}`}
                >{`$ ${formatNumber(currentPL)}`}</span>
              );
            },
            size: 125,
            maxSize: 150,
            minSize: 100,
            Footer: 'Current P&L',
          },

          {
            id: 'status',
            accessorKey: 'status',
            header: (
              <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
                status
              </h6>
            ),
            cell: (info) => <StatusCell row={info.row} />,
            size: 125,
            maxSize: 150,
            minSize: 120,
            Footer: 'Status',
          },
        ],
      },
      {
        header: (
          <h6 className='px-4 py-1 text-center border-l-[6px] border-gray-400 whitespace-nowrap text-ellipsis w-full text-xs bg-gray-100 font-medium text-gray-500 uppercase tracking-wider '>
            Premium Decays for Buyout Time
          </h6>
        ),
        id: 'premiumDecay',
        accessorKey: 'premiumDecay',
        columns: generateDecayColumns(),
        enableHiding: true,
      },
      {
        id: 'CloseTradesDetails',
        header: (
          <h6
            className={` px-4 py-1 text-center whitespace-nowrap text-ellipsis w-full ${getBgColor(
              'CloseTradesDetails'
            )} text-xs font-medium border-x-[6px] border-gray-400  text-gray-500 uppercase tracking-wider`}
          >
            Close Trades Details
          </h6>
        ),
        columns: [
          {
            id: 'closingDate',
            accessorKey: 'closingDate',
            header: (
              <h6
                className={` px-4 py-1 text-center whitespace-nowrap text-ellipsis w-full ${getBgColor(
                  'closingDate'
                )} text-xs font-medium text-gray-500 uppercase border-l-[6px] border-gray-400  tracking-wider`}
              >
                closing Date
              </h6>
            ),
            cell: (info) => (
              <DateCell
                updateData={updateData}
                row={info.row}
                columnId={'closingDate'}
                page='portfolio'
              />
            ),
            size: 125,
            maxSize: 200,
            minSize: 120,
            Footer: 'Closing Date',
          },
          {
            id: 'closingTradeType',
            accessorKey: 'closingTradeType',

            header: (
              <h6
                className={` px-4 py-1 text-center whitespace-nowrap text-ellipsis w-full ${getBgColor(
                  'closingTradeType'
                )} text-xs font-medium  text-gray-500 uppercase tracking-wider`}
              >
                Trade Type
              </h6>
            ),
            cell: (info) => {
              const options =
                info.row.getValue('putCall') === 'put'
                  ? [
                    { value: 'BTC', label: 'BTC' },
                    { value: 'BTC(rolled)', label: 'BTC (rolled)' },
                    { value: 'EXP', label: 'EXP' },
                    { value: 'EXP(assigned)', label: 'EXP (assigned)' },
                  ]
                  : [
                    { value: 'BTC', label: 'BTC' },
                    { value: 'BTC(rolled)', label: 'BTC (rolled)' },
                    { value: 'EXP', label: 'EXP' },
                    { value: 'EXP(exercised)', label: 'EXP (exercised)' },
                  ];
              return (
                <DropdownCell
                  row={info.row}
                  options={options}
                  updateData={updateData}
                  columnId={'closingTradeType'}
                  page='portfolio'
                />
              );
            },
            size: 150,
            maxSize: 200,
            minSize: 110,
            Footer: 'Closing Trade Type',
          },
          {
            id: 'closingPremium',
            accessorKey: 'closingPremium',
            header: (
              <h6
                className={` px-4 py-1 text-center whitespace-nowrap text-ellipsis w-full ${getBgColor(
                  'closingPremium'
                )} text-xs font-medium text-gray-500 uppercase tracking-wider`}
              >
                closing Premium
              </h6>
            ),
            size: 150,
            maxSize: 200,
            minSize: 125,
            // =====================> This is user input and API if possible.
            Footer: 'Closing Premium',
          },
          {
            id: 'finalPL',
            accessorKey: 'finalPL',
            header: (
              <h6
                className={` px-4 py-1 text-center whitespace-nowrap text-ellipsis w-full ${getBgColor(
                  'finalPL'
                )} text-xs font-medium border-r-[6px] border-gray-400  text-gray-500 uppercase tracking-wider`}
              >
                final PL
              </h6>
            ),
            cell: (info) => {
              return(
              <FinalPLCell
                updateData={updateData}
                row={info.row}
                columnId={'finalPL'}
                page='portfolio'
              />
            )},
            footer: () => (
              <div className='  flex flex-col text-sm '>
                <div className='px-4 py-1 flex justify-end items-center text-end font-medium bg-[#E3F2D9]'>
                  {`$ ${footerSums.totalFinalPL}`}
                </div>
                <div
                  className={`px-4 py-1 flex justify-end items-center text-end h-[28px] font-medium `}
                >{` `}</div>
                <div
                  className={`px-4 py-1 flex justify-end items-center text-end h-[28px] font-medium `}
                >{` `}</div>
              </div>
            ),
            size: 160,
            maxSize: 200,
            minSize: 125,
          },
        ],
      },
      {
        id: 'Fundamentals',
        header: (
          <h6
            className={` px-4 py-1 text-center whitespace-nowrap text-ellipsis w-full ${getBgColor(
              'finalPL'
            )} text-xs font-medium text-gray-500 uppercase tracking-wider`}
          >
            Fundamentals
          </h6>
        ),
        columns: [
          {
            id: 'sector',
            accessorKey: 'sector',
            header: (
              <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
                sector
              </h6>
            ),
            cell: (info) => (
              <span className='px-4 py-1 w-full text-start'>
                {info.getValue()}
              </span>
            ),
          },
          {
            id: 'industry',
            accessorKey: 'industry',
            header: (
              <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
                industry
              </h6>
            ),
            cell: (info) => (
              <span className='px-4 py-1 w-full text-start'>
                {info.getValue()}
              </span>
            ), // ====================> API
            Footer: 'Industry',
          },
          {
            id: 'marketCap',
            accessorKey: 'marketCap',
            header: (
              <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
                market Cap
              </h6>
            ),
            cell: (info) =>
              info.getValue() ? (
                <MarketCapCell value={info.getValue()} />
              ) : null, // ====================> API
            Footer: 'Market Cap',
          },
          {
            id: 'peRatio',
            accessorKey: 'peRatio',
            header: (
              <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
                P/e Ratio
              </h6>
            ),
            cell: (info) => (
              <span className='w-full px-4 py-1 flex items-center justify-end text-end'>
                {Math.floor(info.getValue()) || null}
              </span>
            ),
            size: 100,
            maxSize: 200,
            minSize: 50,
            Footer: 'P/E Ratio',
          },
          {
            id: 'employees',
            accessorKey: 'employees',
            header: (
              <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
                employees
              </h6>
            ),
            cell: (info) => {
              const value = info.getValue() || 0;
              const percentage = (value / 500000) * 100;
              return (
                <div className='px-4  relative h-full w-full rounded overflow-hidden table-cell'>
                  <div className='relative h-6 bg-gray-400 flex rounded'>
                    <div
                      className='absolute left-0 h-6 bg-blue-500 rounded'
                      style={{ width: `${percentage}%` }}
                    ></div>
                    <div className='absolute w-full inset-0 flex justify-center  items-center'>
                      <span className='w-full pr-2 text-end text-white'>
                        {value.toLocaleString()}
                      </span>
                    </div>
                  </div>
                </div>
              );
            }, // ====================> API
            Footer: 'Number of Employees',
          },
          {
            id: 'averageVolume',
            accessorKey: 'averageVolume',
            header: (
              <h6 className='px-4 py-1 text-center whitespace-nowrap text-ellipsis text-xs font-medium text-gray-500 uppercase tracking-wider '>
                average Volume
              </h6>
            ),
            cell: (info) => {
              const value = info.getValue() || 0;
              const percentage = (value / 100000000) * 100;
              return (
                <div className='px-4  relative h-full w-full rounded overflow-hidden table-cell'>
                  <div className='relative h-6 bg-gray-400 flex rounded'>
                    <div
                      className='absolute left-0 h-6 bg-blue-500 rounded'
                      style={{ width: `${percentage}%` }}
                    ></div>
                    <div className='absolute w-full inset-0 flex justify-center  items-center'>
                      <span className='w-full pr-2 text-end text-white '>
                        {value.toLocaleString()}
                      </span>
                    </div>
                  </div>
                </div>
              );
            }, // ====================> API
            Footer: 'Average Volume',
          },
        ],
      },
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnVisibility, footerSums]
  );
};
