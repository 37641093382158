import { lazy, Suspense } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Outlet,
  Navigate,
} from 'react-router-dom';
import * as PATHS from './constants/routes';
import { useAuth } from './context/auth';
import LoadingPage from './pages/LoadingPage';
import { useStateCtx } from './context/appContext';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import Dashboard from './pages/Dashboard';
import 'react-datepicker/dist/react-datepicker.css';
import Wheelapp from './assets/Wheelapp.png';
import Portfolio from './pages/Portfolio';
import WatchList from './pages/WatchList';
import WheelIncome from './pages/WheelIncome';
import BreakEven from './pages/BreakEven';
import AssignedStock from './pages/AssignedStock';
import TradingHistory from './pages/TradingHistory';
import AboutUs from './pages/AboutUs';

const Login = lazy(() => import('./pages/Login'));

const PrivateRoute = () => {
  const { isLoggedIn,loading } = useAuth();
  // const { user } = useAuth();
  const { activeMenu, windowHeight } = useStateCtx();
  if (loading) {
    return <LoadingPage />;  // Display loading screen while token or visited pages are loading
  }
  return isLoggedIn ? (
    <div className='w-full h-full '>
      <div className='flex relative'>
        <nav
          className={
            activeMenu
              ? 'w-72 h-full z-50 fixed'
              : 'w-0 dark:bg-dark-background dark:bg-opacity-90'
          }
        >
          <Sidebar />
        </nav>
        <div
          className={`min-h-screen w-full dark:bg-dark-background ${
            activeMenu ? 'md:ml-72' : 'flex-2'
          }`}
        >
          <header className='w-full'>
            <Navbar />
          </header>
          <main
            style={{ maxHeight: `${windowHeight - 72}px` }}
            className='w-full mx-auto  dark:text-white 
           flex justify-center h-full overflow-y-scroll bg-light-background dark:bg-dark-background'
          >
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  ) : (
    <Navigate to={PATHS.LOGIN} />
  );
};

const PublicRoute = () => {
  const { isLoggedIn } = useAuth();
  return !isLoggedIn ? (
    <div className='w-full h-screen flex flex-col justify-center lg:flex-row lg:justify-between bg-light-background dark:bg-dark-background '>
      <div className='lg:w-2/3 mx-auto items-center hidden lg:flex justify-center'>
        <img
          // src={Wheelapp}
          alt='Image here'
          className='w-2/3 aspect-auto  hidden lg:block'
        />
      </div>
      {/* <div className='overflow-auto h-full'> */}
      <Outlet />
      {/* </div> */}
    </div>
  ) : (
    <Navigate to={PATHS.DASHBOARD} />
  );
};
const App = () => {
  return (
    <div>
      <div className={`flex justify-center items-center h-full`}>
        <Suspense fallback={<LoadingPage />}>
          <BrowserRouter>
            <Routes>
              <Route element={<PublicRoute />}>
                <Route path={PATHS.LOGIN} element={<Login />} />
              </Route>
              <Route element={<PrivateRoute />}>
                <Route path={PATHS.DASHBOARD} element={<Dashboard />} />
                <Route path={PATHS.PORTFOLIO} element={<Portfolio />} />
                <Route path={PATHS.WATCH_LIST} element={<WatchList />} />
                <Route path={PATHS.WHEEL_INCOME} element={<WheelIncome />} />
                <Route path={PATHS.BREAKEVEN} element={<BreakEven />} />
                <Route path={PATHS.ASSIGNED_STOCK} element={<AssignedStock />} />
                <Route
                  path={PATHS.TRADING_HISTORY}
                  element={<TradingHistory />}
                />
                <Route path={PATHS.ABOUT_US} element={<AboutUs />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </Suspense>
      </div>
    </div>
  );
};

export default App;
