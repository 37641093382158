import axios from 'axios';

export const searchTickers = async (query, token) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/search-tickers`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { query }, // Send the search query to the backend
    });
    console.log(response)
    return response.data; // Backend returns the search results
  } catch (error) {
    console.error('Error fetching tickers from backend:', error);
    return [];
  }
};
