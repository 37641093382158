import {
  MdOutlineCancel,
  MdOutlinePlaylistAdd,
  MdOutlineSave,
  MdOutlineWatchLater,
} from 'react-icons/md';
import ConfirmationModal from '../components/ConfirmationModal';
import { DebouncedInput } from './DebouncedInput';
import { TbIrregularPolyhedronPlus, TbPlayerTrackNext, TbPlayerTrackPrev } from 'react-icons/tb';
import { useEffect, useRef, useMemo } from 'react';
import { zonedTimeToUtc } from 'date-fns-tz';
import { startOfDay } from 'date-fns';
import { ImEyePlus } from "react-icons/im";


export const ConfirmationModalContent = ({
  isOpen,
  title,
  message,
  onConfirm,
  onCancel,
}) => (
  <ConfirmationModal
    isOpen={isOpen}
    title={title}
    message={message}
    onConfirm={onConfirm}
    onCancel={onCancel}
  />
);

export const SearchCell = ({ value, onChange, className, placeholder }) => (
  <DebouncedInput

    value={value ?? ''}
    onChange={onChange}
    className="px-2 py-1 w-full rounded-lg outline-none text-md shadow-sm transition-all duration-200 ease-in-out transform hover:scale-105"
    placeholder={placeholder}
  />
);

export const ActionBar = ({ title, icon, newRow, handleSave, cancelNewRow, addRow, setSort, refresh, page }) => (
  <div id='addTrade' className="flex space-x-4 ">
    {newRow ? (
      <>
        <button
          className="flex items-center text-white bg-blue-600 hover:bg-blue-700 p-2 rounded-lg transition-all duration-200 ease-in-out transform hover:scale-110"
          onClick={() => {
            setSort(true);
            handleSave();
          }}
        >
          <MdOutlineSave className="w-4 h-4" />
          Save
        </button>
        <button
          className="flex items-center text-white bg-red-600 hover:bg-red-700 p-2 rounded-lg transition-all duration-200 ease-in-out transform hover:scale-110"
          onClick={() => {
            setSort(true);
            cancelNewRow();
          }}
        >
          <MdOutlineCancel className="w-4 h-4" />
          Cancel
        </button>
      </>
    ) : (
      <button
        className={`flex items-center gap-2 text-white ${title === 'Add new put' ? 'bg-green-600 hover:bg-green-700' : title === 'Add new call' ? 'bg-red-600 hover:bg-red-700' : 'bg-blue-600 hover:bg-blue-700'} font-bold px-2 py-1 rounded-lg `}
        onClick={() => {
          addRow();
          setSort(false);
        }}
      >
        {icon}
        {title}
      </button>
    )}
  </div>
);


export const ContextMenu = ({
  isVisible,
  onClose,
  allColumnsVisible,
  toggleAllColumnsVisibility,
  columns,
}) => {
  const dropdownRef = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isVisible && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isVisible, onClose]);


  // Function to render individual checkboxes for columns or column groups
  const renderColumnCheckboxes = (column) => {
    if (['delete', 'color'].includes(column.id)) {
      return null;
    }
    // If it's a grouped header
    if (column.columns.length > 0) {
      return (
        <div key={column.id} className='border-b border-gray-200 last:border-0'>
          <div className='px-4 py-1 font-semibold overflow-y-auto text-gray-800 text-sm bg-gray-100'>{column.id}</div>
          {column.columns.map((subColumn) => {
            if (['delete', 'color'].includes(subColumn.id)) {
              return null;
            }
            return (
              <div key={subColumn.id} className='px-4 py-1 flex items-center space-x-2 text-sm text-gray-700 hover:bg-gray-50 cursor-pointer'>
                <input
                  type='checkbox'
                  className='form-checkbox rounded text-[#3e64de] focus:ring-[#3e64de]'
                  checked={subColumn.getIsVisible()}
                  onChange={subColumn.getToggleVisibilityHandler()}
                />
                <span>{subColumn.columnDef.header}</span>
              </div>
            )
          })}
        </div>
      );
    }
    // If it's an individual column
    return (
      <div key={column.id} className='px-4 py-1 flex items-center space-x-2 text-sm text-gray-700 hover:bg-gray-50 cursor-pointer'>
        <input
          type='checkbox'
          className='form-checkbox rounded text-[#3e64de] focus:ring-[#3e64de]'
          checked={column.getIsVisible()}
          onChange={column.getToggleVisibilityHandler()}
        />
        <span>{column.columnDef.header}</span>
      </div>
    );
  };

  return (
    isVisible && (
      <div ref={dropdownRef} className="bg-white fixed  z-50 border border-gray-300 rounded-lg shadow-lg max-h-80 overflow-auto transition-all duration-300 ease-in-out">
        <div className='px-4 py-1 border-b border-gray-300 flex items-center space-x-2 text-sm text-gray-700 bg-gray-200'>
          <input
            type='checkbox'
            className='form-checkbox rounded text-[#3e64de] focus:ring-[#3e64de]'
            checked={allColumnsVisible}
            onChange={toggleAllColumnsVisibility}
          />
          <span className='font-semibold'>Toggle All</span>
        </div>
        <div className='overflow-y-auto par !border-0 '>
          {columns.map(renderColumnCheckboxes)}
        </div>
      </div>
    )
  );
};





export const PaginationControls = ({
  canPreviousPage,
  canNextPage,
  setPageIndex,
  nextPage,
  pageIndex,
  pageCount,
}) => (
  <div className='px-4 py-3 bg-white flex items-center justify-between border-t relative border-gray-200 sm:px-6'>
    <button
      onClick={() => setPageIndex(0)}
      disabled={!canPreviousPage}
      className='relative inline-flex items-center px-4 py-1 border border-gray-300 text-sm font-medium rounded-md text-[#3e64de] bg-white hover:bg-gray-50 cursor-pointer'
    >
      <TbPlayerTrackPrev className='w-4 h-4 ' />
    </button>{' '}
    <span className='text-sm text-gray-700'>
      Page{' '}
      <strong>
        {pageIndex + 1} of {pageCount}
      </strong>{' '}
    </span>
    <button
      onClick={nextPage}
      disabled={!canNextPage}
      className='relative inline-flex items-center px-4 py-1 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 cursor-pointer'
    >
      <TbPlayerTrackNext className='w-4 h-4 text-[#3e64de]' />
    </button>
  </div>
);


export const getTradeStatus = (row, userTimeZone) => {
  // if (row?.original?.name) {
  const getValue = row.getValue ?
    (columnId) => row.getValue(columnId) :
    (columnId) => row[columnId];

  const convertToStartOfDayUTC = (localDate) => {
    // Convert local time to the start of the day in local timezone, then to UTC
    if (localDate) {
      const startOfToday = startOfDay(localDate); // This is in local time
      return zonedTimeToUtc(startOfToday, userTimeZone); // Convert to UTC
    }
    return null;
  };

  const isTradeDetailsComplete = ['name', 'tradeDate', 'expirationDate', 'strike', 'premium', 'numberOfOptions'].every((columnId) => Boolean(getValue(columnId)));

  let currentDate = convertToStartOfDayUTC(new Date());
  const closingDate = convertToStartOfDayUTC(getValue('closingDate') ? new Date(getValue('closingDate')) : null);
  let tradeDate = convertToStartOfDayUTC(getValue('tradeDate') ? new Date(getValue('tradeDate')) : null);

  const isTradeDateReached = tradeDate && tradeDate <= currentDate;
  const isClosed = closingDate && (getValue('closingPremium') !== null || undefined)

  let value, color, tooltip;

  if (isClosed && isTradeDetailsComplete && isTradeDateReached) {
    value = 'closed';
    color = '#808080'; // Gray
    tooltip = 'Closed';
  } else if (isTradeDetailsComplete && isTradeDateReached) {
    value = 'active';
    color = '#4CAF50'; // Green
    tooltip = 'Active';
  } else {
    value = 'pending';
    color = '#FFEB3B'; // Yellow
    tooltip = 'Pending';
  }

  return { value, color, tooltip };
  // }
  // return { value: null, color: null, tooltip: null };
};




export const formatGroupTitle = (date) => {
  const dateObj = new Date(date);
  return dateObj.toLocaleString('default', { month: 'long', year: 'numeric' });
};