import React, { Fragment, useCallback, useState } from 'react';
import { useAuth } from '../../context/auth';
import { flexRender } from '@tanstack/react-table';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import { formatNumber, sumBreakEvenFinalPL } from '../../utils/mathHelpers';
import OperationControls from '../OperationControls';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { MdTrendingUp } from 'react-icons/md';
import { deleteTradeWithOperations } from '../../services/breakevenServices';
import ConfirmationModal from '../ConfirmationModal';
const BreakEvenTableBody = ({ table, onDeleteTrade }) => {
  const { token } = useAuth();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tradeToDelete, setTradeToDelete] = useState(null);
  const toggleExpanded = useCallback((rowId, forceExpand = false) => {
    const currentlyExpanded = table.getState().expanded[rowId];
    if (forceExpand && !currentlyExpanded) {
      table.setExpanded({
        ...table.getState().expanded,
        [rowId]: true
      });
    } else if (!forceExpand) {
      table.setExpanded({
        ...table.getState().expanded,
        [rowId]: !currentlyExpanded
      });
    }

  }, [table]);

  const handleConfirmDelete = async () => {
    try {
      await deleteTradeWithOperations(tradeToDelete, token);
      // Refresh the table or handle post-deletion logic here
    } catch (error) {
      console.error('Failed to delete trade and its operations:', error);
    } finally {
      onDeleteTrade(tradeToDelete);
      setIsModalOpen(false);
      setTradeToDelete(null);
    }
  };

  const handleDeleteClick = (tradeId) => {
    setTradeToDelete(tradeId);
    setIsModalOpen(true);
  };

  // OperationControls with modified passing of toggle function
  const renderGroupRow = (row, index) => {
    const isExpanded = table.getState().expanded[row.id];
    const totalBreakEvenFinalPL = row.original.subRows[0].breakEvenStrike - (sumBreakEvenFinalPL(row.original.subRows) / row.original.subRows[0].breakEvenShares);

    return (
      <tr key={index} className="bg-blue-200 hover:bg-blue-300 cursor-pointer text-sm" onClick={() => toggleExpanded(row.id)}>
        <td colSpan={table.getAllLeafColumns().length}>
          <div className="flex items-center justify-between px-2 py-1 gap-12">
            <div className="flex w-1/3">
              <div className='flex items-center w-4/6 '>
                <button id='deleteTradeButton' onClick={(e) => { e.stopPropagation(); handleDeleteClick(row.original.id); }} >
                  <RiDeleteBin6Line className='mx-2 text-red-600 w-5 h-5 cursor-pointer' />
                </button>
                <h6 className="font-bold  truncate">{row.original.original.companyId.name}</h6>
              </div>
              <h6 className="font-bold w-2/6 uppercase truncate">{row.original.original.ticker}</h6>
            </div>
            <div className="flex items-center flex-nowrap justify-between flex-1">
              {/* Add your centered element here */}
              <span className='font-semibold mr-2 '>Adjusted BE price:</span>
              <span className='font-bold text-green-700'>{`$ ${formatNumber(totalBreakEvenFinalPL)}`}</span>
            </div>
            <div className="flex items-center justify-end w-1/3 gap-4">
              <OperationControls
                rowId={row.id}
                onAdd={() => table.options.meta?.addRow(row.index, row.original.id)}
                onSave={() => table.options.meta?.saveOperation(row.index, row.original.id)}
                onCancel={() => table.options.meta?.cancelNewOperation(row.index, row.original.id)}
              />
              {isExpanded ? <MdExpandLess /> : <MdExpandMore />}
            </div>
          </div>
        </td>
      </tr>
    );
  };
  // Render a single detail row for breakeven operations
  const renderRow = (row, index) => {
    return (
      <tr key={row.id} className="bg-white ">
        {row.getVisibleCells().map(cell => (
          <td key={cell.id} className="text-ellipsis table-cell bg-inherit whitespace-nowrap  ">

            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        ))}
      </tr>
    );
  };

  return (
    <>
      <tbody className="bg-white divide-y divide-gray-200 text-sm">
        {table.getRowModel().rows.map((row, index) => (
          row.depth === 0 && (
            <Fragment key={`group-${row.id}`}>
              {renderGroupRow(row, index)}
              {row.getIsExpanded() && row.subRows.map((subRow, subIndex) => renderRow(subRow, `${row.id}-${subIndex}`))}
            </Fragment>
          )
        ))}{
          table.getRowModel().rows.length === 0 && (
            <tr>
              <td
                colSpan={table.getAllLeafColumns().length + 1}
                className='px-4 py-2 whitespace-nowrap text-center'
              >
                <span className='text-xs text-gray-500 flex flex-col justify-center items-center'>
                  <MdTrendingUp className='w-24 h-24' />
                  <h6 className='font-semibold text-lg'>
                    No trades available
                  </h6>
                </span>
              </td>
            </tr>
          )
        }
      </tbody>
      <ConfirmationModal
        isOpen={isModalOpen}
        title="Confirm Deletion"
        message="Are you sure you want to delete this trade and all its associated operations?"
        onConfirm={handleConfirmDelete}
        onCancel={() => setIsModalOpen(false)}
      /></>
  );
};

export default BreakEvenTableBody;
